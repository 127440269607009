<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect id="SectionWhoAreWe">
    <Wrap class="lg">
      <h2>Apie „Saulės bendruomenę“ </h2>
    </Wrap>
    <Wrap class="md">
      <div class="textContainer">
        <p>
          „Saulės bendruomenė“ vysto, stato ir prižiūri didelio masto saulės parkus, suteikiančius galimybę tiek privatiems, tiek verslo klientams naudoti saulės energiją, pagamintą geografiškai nutolusioje saulės elektrinėje. Patyrusi komanda užtikrina kokybišką saulės parkų įgyvendinimą ir sėkmingą žaliosios energijos generaciją savo klientams.
        </p>
        <p>
          „Saulės bendruomenė“ jau sėkmingai įgyvendino 2 500 kW galios projektus, kurie švaria saulės energija sėkmingai aprūpina daugiau nei 504 namų ūkius bei verslus.
        </p>
        <p>
          Šiuo metu komanda aktyviai vysto dar 3 projektus, kurių suminė galia viršija 5000 kW.
        </p>
      </div>
    </Wrap>
  </Sect>
</template>

<script>

export default {
}
</script>

<style lang="scss">
#SectionWhoAreWe {
  h2 {
    @include dotAbove(1.5rem);
    margin: 3rem 0;
  }

  .textContainer {
    margin-bottom: 3rem;
    margin-top: 3rem;

    > p {
      margin-bottom: 2rem;
    }
  }
}
</style>
