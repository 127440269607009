<template>
  <div
    class="FeatureImage"
    :class="{ hover: isHover, clickable: video }"
    @click="toggle"
    @keypress.enter="toggle">
    <div class="video-bg">
      <Pic
        v-if="image"
        alt="Video apie Saulės Bendruomenę"
        :formats="formats"
        :src="image" />
      <div
        class="play"
        @focusin="isHover = true"
        @focusout="isHover = false"
        @mouseleave="isHover = false"
        @mouseover="isHover = true">
        <Pic
          alt="Paleisti video"
          src="../../../assets/landing/video-play-new.png" />
      </div>
    </div>
    <div v-if="$slots.default" class="slot-cont">
      <div class="slot">
        <slot />
      </div>
    </div>
    <FeatureVideoModal v-if="video" :isOpen.sync="isOpenVideo" :src="video" />
  </div>
</template>

<script>
import FeatureVideoModal from './FeatureVideoModal'

export default {
  components: { FeatureVideoModal },
  props: {
    formats: Array,
    image: String,
    video: String,
  },
  data() {
    return {
      isHover: false,
      isOpenVideo: false,
    }
  },
  methods: {
    toggle() {
      this.isOpenVideo = !this.isOpenVideo
    },
  },
}
</script>

<style lang="scss">
.FeatureImage {
  padding: 0 1rem 1rem;
  position: relative;
  text-align: center;
  width: 100%;
  @include md {
    margin: 0 auto;
    max-width: 80%;
  }
  @include lg {
    max-width: 100%;
  }

  img {
    @include trans;
    display: inline-block;
    max-width: 100%;
    width: auto;
  }

  .play {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    > img {
      @include trans;
      width: 60px;
      @include md {
        width: auto;
      }
    }

    &:hover {
      > img {
        transform: scale(1.1);
      }
    }
  }

  .slot-cont {
    // align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;

    .slot {
      align-items: center;
      display: flex;
      max-width: 420px;
      text-align: left;
      width: 90%;
      @include lg {
        width: 75%;
      }
      @include xxl {
        width: 70%;
      }

      > div {
        width: 100%;
      }
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.hover {
    .video-bg > picture > img {
      filter: blur(2px) sepia(0.3);
    }
  }
}
</style>
