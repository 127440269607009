<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect id="SectionWhyChooseUs">
    <Wrap class="lg">
      <h2>Tapkite mūsų klientu</h2>
    </Wrap>
    <Wrap class="md">
      <div class="textContainer">
        <p>
          Vystydama projektus „Saulės bendruomenės“ komanda išskirtinį dėmesį skiria įrangos pasirinkimui. Projektuose montuojama tik patikima ir išbandyta pasaulyje pirmaujančių gamintojų įranga.
        </p>
        <p>
          Didelės apimties nutolusioms saulės elektrinėms – tiek jų vystymui, rangai, tiek aptarnavimui – „Saulės bendruomenė“ kelia aukščiausius kokybės standartus, taip užtikrinant projekto kokybę bei ilgaamžiškumą.
        </p>
        <p>
          Visą informaciją apie projektus galite rasti
          <Route to="SolarPlants">
            čia.
          </Route>
        </p>
      </div>
    </Wrap>
  </Sect>
</template>

<script>

export default {
}
</script>

<style lang="scss">
#SectionWhyChooseUs {
  a {
    text-decoration: underline;
  }

  h2 {
    @include dotAbove(1.5rem);
    margin: 3rem 0;
  }

  .textContainer {
    margin-bottom: 3rem;
    margin-top: 3rem;

    > p {
      margin-bottom: 2rem;
    }
  }
}
</style>
