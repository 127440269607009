<template>
  <ModalCont v-if="isOpen" class="FeatureVideoModal">
    <iframe
      allow="accelerometer; autoplay; encrypted-media; fullscreen; gyroscope; picture-in-picture"
      allowfullscreen
      frameborder="0"
      :height="~~(width / ratio)"
      :src="`${src}?autoplay=1`"
      title="Video"
      :width="width" />
  </ModalCont>
</template>

<script>
import { getters } from '../../utils'

export default {
  props: {
    isOpen: Boolean,
    src: String,
  },
  data() {
    return {
      ratio: 16 / 9,
    }
  },
  computed: {
    width() {
      const limit = document.querySelector('#FeatureVideoModal .limit')
      // NOTE: a redundant use of screenWidth to force vue refetch clientWidth
      const maxWidth = limit
        // eslint-disable-next-line sonarjs/no-identical-expressions
        ? limit.clientWidth * (this.screenWidth / this.screenWidth)
        : this.screenWidth

      return Math.min(840, maxWidth)
    },
    ...getters('screenWidth'),
  },
}
</script>

<style lang="scss">
.FeatureVideoModal {
  iframe {
    display: block;
  }
}
</style>
