<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect id="SectionReservePlant">
    <div class="video">
      <Wrap class="xl">
        <FeatureImage :formats="['webp']" image="../../../assets/landing/video.png" video="https://www.youtube.com/embed/by3-0VcAgFA">
          <slot name="image" />
        </FeatureImage>
      </Wrap>
    </div>
  </Sect>
</template>

<script>
import FeatureImage from 'views/components/landing/FeatureImage'

export default {
  components: {
    FeatureImage,
  },
}
</script>

<style lang="scss">
#SectionReservePlant {
  @include roundSvgDown('../../../assets/ellipse.svg');
  background: $black-bis;
  padding-bottom: 4rem;
  position: relative;

  // .dot {
  //   @include dotAbove(1.5rem);
  // }

  // h2 {
  //   @include dotAbove(1.5rem);
  //   margin: 3rem 0;
  // }

  .video {
    @include dotAbove(1.5rem);
    margin-top: -4rem;
    position: relative;
    z-index: 2;
    @include md {
      margin-top: -8rem;
    }
  }

  .video-bg {
    img {
      border-radius: $radius 0;
      filter: grayscale(100%) brightness(0.5);
    }
  }

  .play {
    img {
      filter: grayscale(0%);
    }
  }

  .textContainer {
    margin-bottom: 3rem;
    margin-top: 3rem;

    > p {
      margin-bottom: 2rem;
    }
  }
}
</style>
